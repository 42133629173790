import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

export type ExchangeResponseDto = {
    commission: number;
    rate: number;
    total: number;
};

export type ExchangeCurrencyParams = {
    fiat_amount: number;
    fiat_currency: string;
    recipient_currency: string;
    promo_code?: string;
};

export type ICheckLimitsProps = {
    fiat_amount?: number;
    fiat_currency: string;
};

export type ITransferLimitsDto = {
    max: number;
    min: number;
};

export type ITransferHistoryDto = {
    merchant_transaction_id: string;
    id_client: number;
    id_display: number;
    created_at: string;
    fiat_amount: number;
    fiat_currency: string;
    payment_method: string;
    promo_code: string;
    recipient_amount: number;
    recipient_card?: string;
    iban?: string;
    recipient_currency: string;
    recipient_name: string;
    recipient_phone: string;
    recipient_surname: string;
    status: string;
    status_lang: string;
};

export type INewTransferParams = {
    fiat_amount: number;
    fiat_currency: string;
    data: ITransferDataDto;
    promo_code?: string;
};

export type ITransferDataDto = {
    recipient_amount: number;
    recipient_card?: string;
    recipient_iban?: string;
    recipient_currency: string;
    recipient_name: string;
    recipient_phone: string;
    recipient_surname: string;
    account_type: string;
    payment_method: string;
    card_cvv: string;
    card_expiration_month: string;
    card_expiration_year: string;
    card_holder_name: string;
    card_number: string;
};

export type ITransferClientResponseDto = {
    error: string;
    merchant_transaction_id: string;
    redirect_url: string;
};

export type TransferCheckProps = {
    fiat_amount: number;
    fiat_currency: string;
    redirect_url: string;
    recipient_currency: string;
};

export type TransferClientCheckResponseDto = {
    fiat_amount: number;
    fiat_currency: string;
    redirect_url: string;
    kyc_status: string;
};

export const setExchangeCalc = <TData = AxiosResponse<ExchangeResponseDto>>(
    exchangeCalcDto: ExchangeCurrencyParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/acquiring/transaction/calculate-exchange-rate`, exchangeCalcDto, options);
};

export const checkLimits = <TData = AxiosResponse<ITransferLimitsDto>>(
    checkLimitsDto: ICheckLimitsProps,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/acquiring/transaction/limit/${checkLimitsDto.fiat_currency}`, options);
};

export const fetchTransferHistory = <TData = AxiosResponse<ITransferHistoryDto[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/acquiring/transaction/history`, options);
};

export const setTransaction = <TData = AxiosResponse<ITransferClientResponseDto>>(
    transaction: INewTransferParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/acquiring/transaction/new`, transaction, {
        ...options,
        headers: {
            'X-Access-Token':
                '01:7ae223a26b3051ef178b02494b70172e47a1014658da0930688f2d00c8c89491:511093822c78f1a9821758bbe19045936b7789b7cb20bc3fb8893f451ff8735e',
        },
    });
};

export const validCard = <TData = AxiosResponse<string>>(body: { card: string }, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/transaction/valid/card/${body.card}`, options);
};

export const validIban = <TData = AxiosResponse<string>>(body: { iban: string }, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/transaction/valid/iban/${body.iban}`, options);
};

export const setTransactionCheck = <TData = AxiosResponse<string>>(
    body: { merchant_transaction_id: string },
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/transaction/check/new`, body, options);
};

export const setUserTransactionCheck = <TData = AxiosResponse<TransferClientCheckResponseDto>>(
    body: TransferCheckProps,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/transaction/user/check`, body, options);
};
