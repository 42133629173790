/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { ICurrencyDto } from '@api/mainServiceAPI';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import { ITransferState, initialTransferState } from './state';

// Slice
export const transferSlice = createSlice({
    name: 'transfer',
    initialState: initialTransferState,
    reducers: {
        setAmountSend: (state: ITransferState, action: PayloadAction<number>) => {
            state.amountSend = action.payload;
        },
        setAmountReceive: (state: ITransferState, action: PayloadAction<number>) => {
            state.amountReceive = action.payload;
        },
        setSendCurrency: (state: ITransferState, action: PayloadAction<ICurrencyDto | undefined>) => {
            state.sendCurrencyMoney = action.payload;
        },
        setReceiveCurrency: (state: ITransferState, action: PayloadAction<ICurrencyDto | undefined>) => {
            state.receiveCurrencyMoney = action.payload;
        },
        setExchangeCalculator: (
            state: ITransferState,
            action: PayloadAction<{
                fiat_amount: number;
                fiat_currency: string;
                recipient_currency: string;
                promo_code?: string;
            }>,
        ) => {},
        setCommission: (state: ITransferState, action: PayloadAction<number>) => {
            state.commission = action.payload;
        },
        setCurrencyRate: (state: ITransferState, action: PayloadAction<number>) => {
            state.currencyRate = action.payload;
        },
    },
});

// Actions
export const { setAmountSend, setAmountReceive, setSendCurrency, setReceiveCurrency, setCommission, setCurrencyRate } = transferSlice.actions;

// Reducer
export const transferReducer = transferSlice.reducer;

// Selectors
export const selectSendAmount = (state: RootState) => state.transferReducer.amountSend;
export const selectReceiveAmount = (state: RootState) => state.transferReducer.amountReceive;
export const selectSendCurrency = (state: RootState) => state.transferReducer.sendCurrencyMoney;
export const selectReceiveCurrency = (state: RootState) => state.transferReducer.receiveCurrencyMoney;
export const selectCommission = (state: RootState) => state.transferReducer.commission;
export const selectCurrencyRate = (state: RootState) => state.transferReducer.currencyRate;
