import { IUserDto } from '@sliceUser';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

export type OrderProps = {
    fiat_amount: number;
    fiat_currency: string;
    promo_code?: string;
};

export type ReviewProps = {
    email: string;
    first_name: string;
    text: string;
    type: 'review' | 'refund' | 'technical support' | 'social networks' | 'partnerships' | 'other' | 'referral';
};

export type CardsProps = {
    card_id: string;
    card_number: string;
    created_at: string;
    id: number;
    updated_at: string;
    user_id: string;
    user_id_mercury: string;
};

export const register = <
    TData = AxiosResponse<{
        token: string;
    }>,
>(
    userDTO: IUserDto,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/acquiring/user/sign-up`, userDTO, options);
};

export const signIn = <
    TData = AxiosResponse<{
        token: string;
    }>,
>(
    user: { email: string; password: string },
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/acquiring/user/sign-in`, user, options);
};

export const loginByGoogle = <TData = AxiosResponse<string>>(): Promise<TData> => {
    return axios.get(`/acquiring/user/login/google`, {});
};

export const fetchUserData = <TData = AxiosResponse<IUserDto>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/acquiring/user/by-token`, options);
};

export const forgetPassword = <TData = AxiosResponse<string>>(body: { email: string }, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/acquiring/user/forget/pass`, body, options);
};

export const resetPassword = <TData = AxiosResponse<string>>(
    body: { email: string; generated_pass: string; new_pass: string },
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/acquiring/user/reset/pass`, body, options);
};

export const changeUserData = <TData = AxiosResponse<IUserDto>>(body: IUserDto, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/acquiring/user/change/profile`, body, options);
};

export const getGoogleAccount = <TData = AxiosResponse<any>>(link: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`${link}`, options);
};

export const getUserOrder = <TData = AxiosResponse<string>>(body: OrderProps, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/acquiring/user/order/new`, body, options);
};

export const setReview = <TData = AxiosResponse<string>>(body: ReviewProps, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/acquiring/user/review/new`, body, options);
};

export const getCards = <TData = AxiosResponse<CardsProps[]>>(body: ReviewProps, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/acquiring/transaction/cards`, options);
};
