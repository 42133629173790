import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PrivacyPage.module.scss';

export const PrivacyPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    function renderListItems() {
        const items = t('privacyPage.sections', { returnObjects: true });

        if (Array.isArray(items)) {
            return items.map((item, index) => (
                <div key={index}>
                    <p className={styles.subtitle}>{item.title}</p>
                    <p className={styles.description}>
                        {item.description.split('\n').map((line: string, lineIndex: string) => (
                            <React.Fragment key={lineIndex}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                    {item.subsections && item.subsections.length > 0 && (
                        <ul>
                            {item.subsections.map((subsection: string, subIndex: string) => (
                                <li className={styles.description} key={subIndex}>
                                    {subsection}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ));
        }

        return null;
    }

    return (
        <>
            <div className={styles.wrapper}>
                <Row gutter={100}>
                    <Col lg={9} sm={24}>
                        <h1 className={styles.title}>{t('privacyPage.title')}</h1>
                    </Col>
                    <Col lg={15} sm={24}>
                        <p className={styles.description}>{t('privacyPage.description')}</p>
                        {renderListItems()}
                    </Col>
                </Row>
            </div>
        </>
    );
};
