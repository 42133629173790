/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentLanguage, setGlobalLoading } from 'src/store/core';

type IInterceptorProps = {
    token: string | undefined | null;
};

export const HttpInterceptor = ({ token }: IInterceptorProps) => {
    const [requestCount, setRequestCount] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const locale = useSelector(selectCurrentLanguage);

    useEffect(() => {
        const ignorePages403 = ['/', '/register', '/send-money'];

        const requestInterceptor = axios.interceptors.request.use((config) => {
            setRequestCount((prev) => prev + 1);

            config.baseURL = process.env.REACT_APP_API_URL;
            config.headers['user_id'] = 'e6969967-badc-4291-be25-13fef069625d';

            config.headers['X-Access-Token'] =
                '01:1bdf5f407098ef34cd199b78cab075af423278d9725805326e86c084bd74de79:384d999c8948ea129c1dd576ec7fe43189f27dc122456060ad697fc98b148f0a';

            if (token) {
                config.headers.Authorization = token;
            }

            if (locale) {
                config.headers['Accept-Language'] = locale;
            }

            return config;
        });

        const responseInterceptor = axios.interceptors.response.use(
            (response) => {
                setRequestCount((prev) => prev - 1);

                return response;
            },
            (error) => {
                setRequestCount((prev) => prev - 1);

                const code = error.response?.status;

                let path = location.pathname.split('?')[0];
                path = path.endsWith('/') ? path.slice(0, -1) : path;

                if ((code === 401 && !ignorePages403.includes(path)) || (code === 403 && !ignorePages403.includes(location.pathname))) {
                    navigate('/login');
                } else {
                    const messageString = error.response?.data.error.message;

                    if (messageString) {
                        message.error(t('rules.unknownError'));
                    }
                }

                return Promise.reject(error);
            },
        );
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [token]);

    useEffect(() => {
        dispatch(setGlobalLoading(requestCount !== 0));
    }, [requestCount]);

    return null;
};
