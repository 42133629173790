import { fetchUserData, loginByGoogle, signIn } from '@api/userServiceAPI';
import { EyeClosed, EyeOpen } from '@icons';
import { IUserDto, setAuthUser, setToken } from '@sliceUser';
import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import { UserTokenLS } from 'src/services';
import styles from './LoginPage.module.scss';

export const LoginPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { validateForm } = useFormValidation(form);
    const navigate = useNavigate();

    const submit = async (formValue: { email: string; password: string }) => {
        await signIn(formValue).then((response) => {
            dispatch(setToken(response.data.token));
            UserTokenLS.setUserToken(response.data.token);
        });
        await fetchUserData().then((res) => {
            dispatch(setAuthUser(res.data as IUserDto));
            navigate('/profile');
        });
    };

    const validate = () => {
        validateForm(['email', 'password']);
    };

    const signInByGoogle = () => {
        loginByGoogle().then((res) => {
            window.location.href = res.data;
        });
    };

    useEffect(() => {
        validate();
    }, []);

    return (
        <>
            <div className={styles.wrapper}>
                <div>
                    <Form
                        form={form}
                        name="login"
                        wrapperCol={{ span: 24 }}
                        initialValues={{ email: '', password: '' }}
                        autoComplete="off"
                        onFinish={submit}
                        onChange={validate}
                        className={styles.form}
                    >
                        <h1 className={styles.title}>{t('loginPage.title')}</h1>
                        <div className={styles.formItem}>
                            <p className={styles.label}>{t('registerPage.labels.email')}</p>
                            <Form.Item
                                name="email"
                                rules={[
                                    { type: 'email', message: t('rules.wrongEmail') },
                                    { required: true, message: t('rules.requiredField') },
                                ]}
                            >
                                <Input className="w-100" autoFocus />
                            </Form.Item>
                        </div>
                        <div className={styles.formItem}>
                            <p className={styles.label}>{t('registerPage.labels.password')}</p>
                            <Form.Item name="password" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                <Input.Password
                                    iconRender={(isOpen) =>
                                        isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                                    }
                                />
                            </Form.Item>
                            <Link to="/change-password" className={styles.forgot}>
                                {t('loginPage.forgot')}
                            </Link>
                        </div>

                        <Button className={styles.button} type="primary" htmlType="submit">
                            {t('loginPage.enter')}
                        </Button>
                        <Button className={styles.buttonGoogle} type="ghost" onClick={signInByGoogle}>
                            {t('loginPage.enterByGoogle')}
                        </Button>
                        <div className={styles.bottomTxt}>{t('loginPage.registered')}</div>
                        <Link to="/register">{t('loginPage.createAccount')}</Link>
                    </Form>
                </div>
            </div>
        </>
    );
};
