import { Button, Col, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './Career.module.scss';

export const Career = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    const viewVacancyClick = () => {
        navigate('/vacancies');
    };

    const renderJobs = () => {
        const jobs = t('careerPage.direction.description', { returnObjects: true });

        if (Array.isArray(jobs)) {
            return jobs.map((job) => (
                <Col lg={7} sm={24} key={job.title} className={styles['job-description']}>
                    <p className={styles['job-title']}>{job.title}</p>
                    <p className={styles['job-text']}>{job.text}</p>
                    <div className="d-flex justify-content-end">
                        <img width={160} src={`/img/${job.img}.png`} alt={job.img} />
                    </div>
                </Col>
            ));
        }

        return null;
    };

    return (
        <div>
            <div className={styles.wrapper}>
                <div className={styles.topContainer}>
                    <Row align="middle" justify="space-between">
                        <Col lg={10} sm={24}>
                            <img className={styles.bigImg} src="/img/career.png" alt="group" />
                        </Col>
                        <Col lg={10} sm={24}>
                            <h1 className={styles.title}>{t('careerPage.title')}</h1>
                            <h2 className={styles.subtitle}>{t('careerPage.subtitle')}</h2>
                            <Button onClick={viewVacancyClick} className="w-lg-75 w-100" type="primary">
                                {t('careerPage.viewVacancies')}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className={styles['direction-container']}>
                    <p className={styles.title}>{t('careerPage.direction.title')}</p>
                    <Row justify="space-between">{renderJobs()}</Row>
                    <div className={styles['world-job-container']}>
                        <Col lg={8} sm={24}>
                            <p className={styles['job-title']}>{t('careerPage.direction.workAroundTheWorld.title')}</p>
                            <p className={styles['job-text']}>{t('careerPage.direction.workAroundTheWorld.text')}</p>
                        </Col>
                        <Button onClick={viewVacancyClick} className="mt-4 mt-md-0" type="primary">
                            {t('careerPage.viewVacancies')}
                        </Button>
                    </div>
                </div>
                <div className={styles.divider} />
            </div>
        </div>
    );
};
