import { PlusIcon } from '@icons';
import { Col, Collapse, Row } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FAQPage.module.scss';

const { Panel } = Collapse;

type FaqTranslationProp = {
    title: string;
    sections: {
        question: string;
        description: string;
        subtitle?: string;
        subsections?: string[];
    }[];
};

export const FaqPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    function renderListItems() {
        const items = t('faqPage.sections', { returnObjects: true }) as FaqTranslationProp;
        if (Array.isArray(items)) {
            return items.map((item, index) => (
                <div className="mb-3" key={index}>
                    <p className={styles.categoryTitle}>{item.title}</p>
                    <Collapse
                        bordered={false}
                        accordion
                        expandIconPosition="end"
                        // defaultActiveKey={'0'}
                        expandIcon={({ isActive }) => (
                            <PlusIcon className={classNames(isActive ? styles.active : styles.nonActive)} rotate={isActive ? 0 : 90} />
                        )}
                        className={styles.collapse}
                    >
                        {item.faq.map((el: FaqTranslationProp['sections'][0], i: number) => (
                            <Panel className="mt-3" key={`${i}`} header={<p className={styles.title}>{el.question} </p>}>
                                <p className={styles.description}>{el.description}</p>
                                {el?.subtitle && <p className={styles.description}>{el.subtitle}</p>}
                                {el?.subsections && (
                                    <ul>
                                        {el.subsections.map((li, index) => (
                                            <li className={styles.description} key={`list-${index}`}>
                                                {li}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            ));
        }

        return null;
    }

    return (
        <>
            <div className={styles.wrapper}>
                <Row gutter={100}>
                    <Col lg={9} sm={24}>
                        <h1 className={styles.title}>{t('faqPage.title')}</h1>
                    </Col>
                    <Col lg={15} sm={24}>
                        {renderListItems()}
                    </Col>
                </Row>
            </div>
        </>
    );
};
