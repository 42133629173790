import { fetchUserData, register, signIn } from '@api/userServiceAPI';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getAuthUser, login, logoutLocal, setAuthUser, setToken, signUp } from './slice';

export const userListenerMiddleware = createListenerMiddleware();

userListenerMiddleware.startListening({
    actionCreator: signUp,
    effect: ({ payload }, listenerApi) => {
        register(payload).then((x) => {
            listenerApi.dispatch(setToken(x.data.token));
            listenerApi.dispatch(getAuthUser());
        });
    },
});

userListenerMiddleware.startListening({
    actionCreator: login,
    effect: ({ payload }, listenerApi) => {
        signIn({ email: payload.email, password: payload.password }).then((x) => {
            listenerApi.dispatch(setToken(x.data.token));
            listenerApi.dispatch(getAuthUser());
        });
    },
});

userListenerMiddleware.startListening({
    actionCreator: logoutLocal,
    effect: (_, listenerApi) => {
        listenerApi.dispatch(setAuthUser(null));
    },
});

userListenerMiddleware.startListening({
    actionCreator: getAuthUser,
    effect: ({ payload }, listenerApi) => {
        fetchUserData()
            .then((x) => {
                listenerApi.dispatch(setAuthUser(x.data));
            })
            .catch(() => {
                // it's a dirty hack
                listenerApi.dispatch(setAuthUser(null));
            });
    },
});
