import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './AmlPage.module.scss';

export const AmlPage = () => {
    const { t } = useTranslation();

    function renderListItems() {
        const items = t('amlPage.content', { returnObjects: true });

        if (Array.isArray(items)) {
            return items.map((item, index) => (
                <li className={styles.description} key={index}>
                    {item}
                </li>
            ));
        }

        return null;
    }

    return (
        <>
            <div className={styles.wrapper}>
                <Row gutter={100}>
                    <Col lg={9} sm={24}>
                        <h1 className={styles.title}>{t('amlPage.title')}</h1>
                    </Col>
                    <Col lg={15} sm={24}>
                        <p className={styles.description}>{t('amlPage.description')}</p>
                        {renderListItems()}
                    </Col>
                </Row>
            </div>
        </>
    );
};
