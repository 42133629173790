import { ChevronRight, FilledCircleIcon } from '@icons';
import { Carousel, Col, Row } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AboutCompany.module.scss';

export const AboutCompany = () => {
    const { t } = useTranslation();
    const carouselRef = useRef<CarouselRef | null>(null);

    const [slideNumber, setSlideNumber] = useState<number>(3.5);
    const [slideToShow, setSlideToShow] = useState<number>(3.5);
    const [initialSlide, setInitialSlide] = useState<number>(-0.5);
    const [slidesToScroll, setSlideToScroll] = useState<number>();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        if (window.innerWidth < 768) {
            console.log('mobile');
            setSlideToShow(1);
            setInitialSlide(0);
            setSlideToScroll(1);
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
            setSlideToShow(2);
            setInitialSlide(0);
            setSlideToScroll(1);
        } else {
            setSlideToShow(3.5);
            setInitialSlide(-0.5);
            setSlideToScroll(0.5);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderMissionDescription = () => {
        const missions = t('aboutCompanyPage.mission.description', { returnObjects: true });

        if (Array.isArray(missions)) {
            return missions.map((mission) => (
                <div key={mission.text} className={styles['mission-description']}>
                    <p className={styles['mission-text']}>{mission.text}</p>
                </div>
            ));
        }

        return null;
    };

    const renderHistoryOfCompany = () => {
        const steps = t('aboutCompanyPage.howStarted.steps', { returnObjects: true });

        if (Array.isArray(steps)) {
            return steps.map((step) => (
                <div key={step.title} className={styles['step-description']}>
                    <div className={styles['slides-divider']}>
                        <FilledCircleIcon />
                        <div className={styles['small-divider']} />
                    </div>
                    <div className={styles['slides-content']}>
                        <div className="f-flex mt-auto mb-3">
                            <p className={styles.year}>{step.year} </p>
                            <p className={styles['step-title']}>{step.title}</p>
                        </div>
                        <p className={styles['step-text']}>{step.description}</p>
                    </div>
                </div>
            ));
        }

        return null;
    };

    const handleChange = (slide: number) => {
        console.log(slide);
        setSlideNumber(slide);
    };

    return (
        <div>
            <div className={styles.wrapper}>
                <h1 className={styles.title}>{t('aboutCompanyPage.title')}</h1>
                <Col lg={14} sm={24}>
                    <h2 className={styles.subtitle}>{t('aboutCompanyPage.subtitle')}</h2>
                </Col>
                <img className={styles.img} src="/img/our_company.png" alt="group" />
                <div className={styles.missionContainer}>
                    <Row justify="space-between">
                        <Col lg={10} sm={24}>
                            <p className={styles.title}>{t('aboutCompanyPage.mission.title')}</p>
                            <p className={styles.subtitle}>{t('aboutCompanyPage.mission.subtitle')}</p>
                        </Col>
                        <Col lg={10} sm={24}>
                            {renderMissionDescription()}
                        </Col>
                    </Row>
                </div>
                <div className={styles.divider} />
            </div>
            <div className={styles.howStarted}>
                <Row className={styles['howStarted-header']} justify="space-between">
                    <Col lg={10} sm={24}>
                        <p className={styles.title}>{t('aboutCompanyPage.howStarted.title')}</p>
                    </Col>
                    <Col lg={10} sm={24}>
                        <div className="d-flex align-items-center justify-content-end">
                            <div className={styles.mirror}>
                                <ChevronRight
                                    onClick={() => {
                                        carouselRef.current?.prev();
                                        // if (slideNumber === 3.5) {
                                        //     return null;
                                        // } else {
                                        //     carouselRef.current?.prev();
                                        // }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <ChevronRight
                                onClick={() => {
                                    carouselRef.current?.next();
                                    //     if (slideNumber === 0) {
                                    //     return null;
                                    // } else {
                                    //     carouselRef.current?.next();
                                    // }
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </Col>
                </Row>
                {slidesToScroll && (
                    <Carousel
                        className={styles.customCarousel}
                        ref={carouselRef}
                        draggable
                        slidesToShow={slideToShow}
                        initialSlide={initialSlide}
                        pauseOnHover
                        afterChange={handleChange}
                        slidesToScroll={slidesToScroll}
                    >
                        {renderHistoryOfCompany()}
                    </Carousel>
                )}
            </div>
            <div className={styles.wrapper}>
                <div className={styles.divider} />
            </div>
        </div>
    );
};
