import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

export interface ICurrencyDto {
    alfa_code3: string;
    char_code: string;
    flag_send: boolean;
    flag_withdraw: boolean;
    name: string;
    country: string;
}

export interface IPaymentMethodResponse {
    char_code: string;
    country_code: string;
    currency_name: string;
    payment_method: string;
    display: boolean;
    display_name: string;
}

export const fetchCurrencyList = <TData = AxiosResponse<ICurrencyDto[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/acquiring/settings/currencies`, options);
};

export const getPaymentMethods = <TData = AxiosResponse<IPaymentMethodResponse[]>>(
    charCode: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/acquiring/settings/payment/method/${charCode}`, options);
};
