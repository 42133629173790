import { forgetPassword, resetPassword } from '@api/userServiceAPI';
import { EyeClosed, EyeOpen } from '@icons';
import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './ChangePassword.module.scss';

export const ChangePassword = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { validateForm } = useFormValidation(form);
    const navigate = useNavigate();

    const [isMailConfirmed, setIsMailConfirmed] = useState<boolean>(false);
    const [isEmailStep, setIsEmailStep] = useState<boolean>(false);

    useEffect(() => {
        validate();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const isConfirmed = params.get('confirm');
        if (isConfirmed) {
            setIsMailConfirmed(true);
        }
    }, []);

    const submit = (formValue: { email: string; generated_pass: string; new_pass: string }) => {
        resetPassword(formValue).then((res) => {
            navigate('/login');
        });
    };

    const confirmMail = (formValue: { email: string }) => {
        forgetPassword(formValue).then((res) => {
            setIsEmailStep(true);
        });
    };

    const validate = () => {
        validateForm(['email', 'generated_pass', 'new_pass', 'repeatPassword']);
    };

    return (
        <>
            <div className={styles.wrapper}>
                <div>
                    <Form
                        form={form}
                        name="login"
                        wrapperCol={{ span: 24 }}
                        initialValues={{ email: '', generated_pass: '', new_pass: '', repeatPassword: '' }}
                        autoComplete="off"
                        onFinish={isMailConfirmed ? submit : confirmMail}
                        onChange={validate}
                        className={styles.form}
                    >
                        <p className={styles.title}>{t('changePasswordPage.changePassword')}</p>
                        {!isEmailStep && (
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('registerPage.labels.email')}</p>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { type: 'email', message: t('rules.wrongEmail') },
                                        { required: true, message: t('rules.requiredField') },
                                    ]}
                                >
                                    <Input className="w-100" autoFocus />
                                </Form.Item>
                            </div>
                        )}
                        {isEmailStep && (
                            <>
                                <div className={styles.formItem}>
                                    <p className={styles.emailLabel}>{t('changePasswordPage.receiveMail')}</p>
                                </div>
                            </>
                        )}
                        {!isEmailStep && isMailConfirmed && (
                            <>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('changePasswordPage.generatedPassword')}</p>
                                    <Form.Item name="generated_pass" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                        <Input className="w-100" />
                                    </Form.Item>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('changePasswordPage.newPassword')}</p>
                                    <Form.Item
                                        rules={[
                                            {
                                                pattern: /^(?=.*[A-Z])(?=.*[!@#$%^&*/])[A-Za-z\d!@#$%^&*/]{8,}$/,
                                                message: t('rules.weakPassword'),
                                            },
                                            { required: true, message: t('rules.requiredField') },
                                        ]}
                                        name="new_pass"
                                    >
                                        <Input.Password
                                            type="password"
                                            className="w-100"
                                            iconRender={(isOpen) =>
                                                isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                                            }
                                        />
                                    </Form.Item>
                                </div>
                                <div className="w-100">
                                    <p className={styles.passwordRule}>{t('registerPage.labels.passwordRule')}</p>
                                </div>
                                <div className={styles.formItem}>
                                    <p className={styles.label}>{t('registerPage.labels.repeatPassword')}</p>
                                    <Form.Item
                                        name="repeatPassword"
                                        rules={[
                                            { required: true, message: t('rules.requiredField') },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('new_pass') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(t('rules.notMatched')));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            type="password"
                                            iconRender={(isOpen) =>
                                                isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </>
                        )}

                        {!isEmailStep && (
                            <Button className={styles.button} type="primary" htmlType="submit">
                                {!isMailConfirmed ? t('changePasswordPage.send') : t('changePasswordPage.change')}
                            </Button>
                        )}
                    </Form>
                </div>
            </div>
        </>
    );
};
