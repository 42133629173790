import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { NewsProps } from '../News/News';
import styles from './NewsPage.module.scss';

export const NewsPage = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    dayjs.extend(customParseFormat);

    const [news, setNews] = useState<NewsProps>();

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    useEffect(() => {
        const items = t('newsPage.content', { returnObjects: true });
        if (Array.isArray(items)) {
            const item = items.find((item: NewsProps) => slugify(item.url) === unSlugify(location.pathname));
            setNews(item);
        }
    }, [i18n.language]);

    function slugify(text: string) {
        return text
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9\s-]/g, '')
            .replace(/[\s-]+/g, '-');
    }

    function unSlugify(url: string) {
        return url.replace(/^\/news\//, '');
    }

    return (
        <div>
            {news && (
                <div className={styles.wrapper}>
                    <div className={styles['img-container']}>
                        <img className={styles.img} src={`/img/news_images/${news.img}.png`} alt={news.img} />
                    </div>

                    <Row gutter={83}>
                        <Col className={styles['author-container']} sm={24} lg={6}>
                            <p className={styles.author}>{news.author}</p>
                            <p className={styles.date}>{dayjs(news.date, 'DD.MM.YYYY').format('DD MMMM YYYY')}</p>
                        </Col>
                        <Col sm={24} lg={14}>
                            <div className={styles['type-container']}>
                                {news.types.map((type) => (
                                    <div key={type} className={styles.type}>
                                        {type}
                                    </div>
                                ))}
                            </div>
                            <h1 className={styles.title}>{news.title}</h1>
                            <h2 className={styles.subtitle}>{news.subtitle}</h2>
                            <div>
                                {news.description.title && <p className={styles['content-title']}>{news.description.title}</p>}
                                {news.description.paragraphs.length > 0 && (
                                    <div>
                                        {news.description.paragraphs.map((paragraph) => (
                                            <p key={paragraph} className={styles.text}>
                                                {paragraph}
                                            </p>
                                        ))}
                                    </div>
                                )}
                                {news.description.list.length > 0 && (
                                    <ul>
                                        {news.description.list.map((item, i) => (
                                            <li className="mb-3" key={`list-item-${i}`}>
                                                {item.title && <p className={styles['list-title']}>{item.title}</p>}
                                                <p className={styles.text}>{item.text}</p>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {news.description.img && (
                                    <img className={styles.smallImg} src={`/img/news_images/${news.description.img}.png`} alt={news.img} />
                                )}
                                {news.description.conclusion.title && (
                                    <p className={styles['conclusion-title']}>{news.description.conclusion.title}</p>
                                )}
                                {news.description.conclusion.paragraphs.length > 0 && (
                                    <div>
                                        {news.description.conclusion.paragraphs.map((paragraph) => (
                                            <p key={paragraph} className={styles.text}>
                                                {paragraph}
                                            </p>
                                        ))}
                                    </div>
                                )}

                                {news.description.ps && <p className={styles.ps}>{news.description.ps}</p>}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};
