import { setReview } from '@api/userServiceAPI';
import { ChevronRight } from '@icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import Carousel, { CarouselRef } from 'antd/es/carousel';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './ReviewPage.module.scss';

type CommentProp = {
    name: string;
    date: string;
    img: string;
    text: string;
};

export const ReviewPage = () => {
    const { t } = useTranslation();
    const carouselRef = useRef<CarouselRef | null>(null);
    const [form] = Form.useForm();
    const { validateForm } = useFormValidation(form);
    dayjs.extend(customParseFormat);

    const [slideNumber, setSlideNumber] = useState<number>(3.5);
    const [slideToShow, setSlideToShow] = useState<number>(1);
    const [initialSlide, setInitialSlide] = useState<number>(0);
    const [slidesToScroll, setSlideToScroll] = useState<number>();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        if (window.innerWidth < 768) {
            console.log('mobile');
            setSlideToShow(1);
            setInitialSlide(0);
            setSlideToScroll(1);
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
            setSlideToShow(2);
            setInitialSlide(0);
            setSlideToScroll(1);
        } else {
            setSlideToShow(3.5);
            setInitialSlide(-0.5);
            setSlideToScroll(0.5);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function chunkArray(array: CommentProp[], chunkSize: number) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    const renderComments = () => {
        const comments = t('reviewsPage.comments', { returnObjects: true });

        if (Array.isArray(comments)) {
            const groupedComments = chunkArray(comments as CommentProp[], 4);
            return groupedComments.map((group, i) => (
                <Row className="d-flex m-0" key={`group-${i}`} gutter={[20, 20]} justify="space-between">
                    {group.map((comment: CommentProp) => (
                        <Col lg={11} key={comment.date} className={styles['comment-description']}>
                            <div className="d-flex align-items-center mb-3">
                                <div className={styles.avatar}>
                                    <img className={styles.img} src={`/img/reviews_images/${comment.img}.png`} alt={comment.img} />
                                </div>
                                <div>
                                    <p className={styles.name}>{comment.name}</p>
                                    <p className={styles.date}>{dayjs(comment.date, 'DD.MM.YYYY').format('DD MMMM YYYY HH:MM')}</p>
                                </div>
                            </div>
                            <p className={styles['comment-text']}>{comment.text}</p>
                        </Col>
                    ))}
                </Row>
            ));
        }

        return null;
    };

    const handleChange = (slide: number) => {
        setSlideNumber(slide);
    };

    const validate = () => {
        validateForm(['email']);
    };

    const submit = (formValue: { name: string; email: string; request: string }) => {
        setReview({
            first_name: formValue.name,
            email: formValue.email,
            type: 'review',
            text: formValue.request,
        });
        setShowModal(false);
    };

    return (
        <div className={styles.wrapper}>
            <Modal width={480} closable={false} onCancel={() => setShowModal(false)} closeIcon={null} open={showModal} footer={null}>
                <div className={styles.modalWrapper}>
                    <p className={styles.modalTitle}>{t('reviewsPage.button')}</p>
                    <Form
                        form={form}
                        name="login"
                        wrapperCol={{ span: 24 }}
                        initialValues={{ name: '', email: '', request: '' }}
                        autoComplete="off"
                        onFinish={submit}
                        onChange={validate}
                        className="w-100"
                    >
                        <div className={styles.formItem}>
                            <p className={styles.label}>{t('contactsPage.modal.labels.name')}</p>
                            <Form.Item name="name" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                <Input placeholder={t('contactsPage.modal.placeholders.name')} className="w-100" autoFocus />
                            </Form.Item>
                        </div>
                        <div className={styles.formItem}>
                            <p className={styles.label}>{t('contactsPage.modal.labels.email')}</p>
                            <Form.Item
                                name="email"
                                rules={[
                                    { type: 'email', message: t('rules.wrongEmail') },
                                    { required: true, message: t('rules.requiredField') },
                                ]}
                            >
                                <Input placeholder={t('contactsPage.modal.placeholders.email')} className="w-100" />
                            </Form.Item>
                        </div>
                        <div className={styles.formItem}>
                            <p className={styles.label}>{t('reviewsPage.feedback')}</p>
                            <Form.Item name="request" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                <TextArea placeholder={t('contactsPage.modal.placeholders.request')} rows={4} />
                            </Form.Item>
                        </div>

                        <Button htmlType="submit" className={styles.checkButton} type="primary">
                            {t('contactsPage.modal.button')}
                        </Button>
                    </Form>
                </div>
            </Modal>
            <h1 className={styles.title}>{t('reviewsPage.title')}</h1>

            {/* {renderComments()} */}
            {slidesToScroll && (
                <Carousel
                    className={styles.customCarousel}
                    ref={carouselRef}
                    draggable
                    slidesToShow={1}
                    initialSlide={0}
                    pauseOnHover
                    dots={false}
                    afterChange={handleChange}
                    slidesToScroll={1}
                >
                    {renderComments()}
                </Carousel>
            )}
            <div className="d-flex align-items-center justify-content-center mt-4">
                <div className={styles.mirror}>
                    <ChevronRight
                        onClick={() => {
                            carouselRef.current?.prev();
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <Button className="ms-3 me-3" type="primary" onClick={() => setShowModal(true)}>
                    {t('reviewsPage.button')}
                </Button>
                <ChevronRight
                    onClick={() => {
                        carouselRef.current?.next();
                    }}
                    style={{ cursor: 'pointer' }}
                />
            </div>
        </div>
    );
};
