import { setReview } from '@api/userServiceAPI';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './Partnership.module.scss';

export const Partnership = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { validateForm } = useFormValidation(form);

    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMBCB888');
    `;
        document.head.appendChild(script);
    }, []);

    const validate = () => {
        validateForm(['email']);
    };

    const submit = (formValue: { name: string; email: string; request: string }) => {
        setReview({
            first_name: formValue.name,
            email: formValue.email,
            text: formValue.request,
            type: 'partnerships',
        });
        setShowModal(false);
    };

    const renderDescription = () => {
        const descriptions = t('partnershipPage.description', { returnObjects: true });

        if (Array.isArray(descriptions)) {
            return (
                <Row gutter={20} justify="space-between">
                    {descriptions.map((description) => (
                        <Col lg={11} sm={24} key={description.title}>
                            <div className={styles['description-container']}>
                                <div className="d-flex flex-column position-relative">
                                    <p className={styles['description-title']}>{description.title}</p>
                                    <div className="d-flex justify-content-center">
                                        <img width={200} src={`/img/${description.img}.png`} alt={description.img} />
                                    </div>
                                </div>
                                <div className={styles.texts}>
                                    <p className={styles['description-text']}>{description.content}</p>
                                    <Button onClick={() => setShowModal(true)} className={styles.button} type="primary">
                                        {t('partnershipPage.button')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            );
        }

        return null;
    };

    return (
        <div>
            <div className={styles.wrapper}>
                <Modal width={480} closable={false} onCancel={() => setShowModal(false)} closeIcon={null} open={showModal} footer={null}>
                    <div className={styles.modalWrapper}>
                        <p className={styles.modalTitle}>{t('contactsPage.modal.title')}</p>
                        <Form
                            form={form}
                            name="login"
                            wrapperCol={{ span: 24 }}
                            initialValues={{ name: '', email: '', request: '' }}
                            autoComplete="off"
                            onFinish={submit}
                            onChange={validate}
                            className="w-100"
                        >
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('contactsPage.modal.labels.name')}</p>
                                <Form.Item name="name" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                    <Input placeholder={t('contactsPage.modal.placeholders.name')} className="w-100" autoFocus />
                                </Form.Item>
                            </div>
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('contactsPage.modal.labels.email')}</p>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { type: 'email', message: t('rules.wrongEmail') },
                                        { required: true, message: t('rules.requiredField') },
                                    ]}
                                >
                                    <Input placeholder={t('contactsPage.modal.placeholders.email')} className="w-100" />
                                </Form.Item>
                            </div>
                            <div className={styles.formItem}>
                                <p className={styles.label}>{t('contactsPage.modal.labels.request')}</p>
                                <Form.Item name="request" rules={[{ required: true, message: t('rules.requiredField') }]}>
                                    <TextArea placeholder={t('contactsPage.modal.placeholders.request')} rows={4} />
                                </Form.Item>
                            </div>

                            <Button htmlType="submit" className={styles.checkButton} type="primary">
                                {t('contactsPage.modal.button')}
                            </Button>
                        </Form>
                    </div>
                </Modal>
                <h1 className={styles.title}>{t('partnershipPage.title')}</h1>
                {renderDescription()}
            </div>
        </div>
    );
};
