export interface IUserDto {
    email?: string;
    birthDate?: string;
    country?: string;
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    passport_authority?: string;
    passport_date_expiry?: string;
    passport_date_issue?: string;
    passport_number?: string;
    agreement?: boolean;
    blocked?: boolean;
    created_at?: string;
    doc_id?: number;
    doc_name?: string;
    id?: string;
    image?: string;
    is_email_confirmed?: boolean;
    is_full_registered?: boolean;
    is_kyc_completed?: boolean;
    phone_number?: string;
    step?: number;
    password?: string;
}

export interface IUserState {
    authUser: IUserDto | undefined | null;
    token: string | undefined | null;
}

export const initialUserState: IUserState = {
    authUser: undefined,
    token: '',
};
